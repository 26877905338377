.MuiDrawer-modal {
  z-index: 9999999!important;
}
/* @charset "UTF-8";
@import 'https://fonts.googleapis.com/css?family=Noto+Sans';
body {
  background: radial-gradient(ellipse farthest-corner at center top, #d8dbe2 0%, #a9bcd0 100%);
  height: 100vh;
  font-family: "Noto Sans", sans-serif;
  background: url("https://hd.unsplash.com/photo-1435777940218-be0b632d06db") no-repeat center center fixed;
}


img {
  display: block;
  margin-left: auto;
  margin-right: auto;
  align-items: center;
  width: 100%;
}
form button {
  position: relative;
  margin: 15px 0 5px 0;
  left: 50%;
  transform: translateX(-50%);
  background: #58a4b0;
  border: none;
  border-radius: 5px;
  padding: 10px;
  color: white;
  width: 85%;
  font-family: "Noto Sans", sans-serif;
  font-size: 14px;
  cursor: pointer;
} */
